<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useBetStore } from "../../../store";
import ProviderSelector from "./ProviderSelector.vue";
import SortSelector from "./SortSelector.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";

import { debounce } from "@/app/utils/debounce";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { useRoute } from "@/app/router";
import { useI18n } from "@/app/composables/useI18n";

const { t } = useI18n();
const route = useRoute();
const store = useBetStore();
const { state, categoriesExtended, updateGames, allGames } = useCasinoService();
const searchText = ref("");

const router = useRouter();

const providerSelectorIsVisible = computed(
  () => state.category.id !== 0 || Object.keys(state.selectedProviders).length == 0
);

watch(
  searchText,
  debounce(() => {
    state.searchText = searchText;
    updateGames();
  }, 700)
);

function handleCategory(category) {
  router.push({ path: `/casino/${category}` });
  allGames.value = [];
  store.setEmptyProvider();
}

const bonusGame = computed(() =>
  categoriesExtended?.value.find((item) => item.name === "Bonus Games")
);
const otherGames = computed(() =>
  categoriesExtended?.value.filter((item) => item.name !== "Bonus Games")
);

const newArray = computed(() => {
  const baseArray = [otherGames.value[0]];

  if (bonusGame.value) {
    baseArray.push(bonusGame.value);
  }

  baseArray.push(...otherGames.value.slice(1));

  return baseArray;
});
</script>

<template>
  <section class="panel-wrapper">
    <div class="categories">
      <BaseButton
        v-for="category in newArray"
        :key="category?.id"
        class="category text"
        :class="
          route.fullPath.substring(8, 13) === category?.slug.substring(0, 5) ? 'activing' : ''
        "
        @click="() => handleCategory(category?.slug)"
      >
        <BaseIcon
          :name="category?.slug"
          :filter="
            route.fullPath.substring(8, 13) === category?.slug.substring(0, 5)
              ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))'
              : ''
          "
          size="20"
        />
        <span class="category-name">{{ t(`casino.${category?.slug}`) }}</span>
      </BaseButton>
    </div>
  </section>
  <div class="filters" :class="{ 'without-provider-selector': !providerSelectorIsVisible }">
    <div
      :class="`search ${
        (route.path == '/casino/home' || route.path == '/casino/providers') && 'search-full'
      }`"
    >
      <div class="prepend-inner">
        <BaseIcon name="magnify" size="20" />
      </div>
      <input
        v-model="searchText"
        aria-autocomplete="none"
        :placeholder="t('casino.search')"
        type="text"
        class="search-input"
      />
    </div>

    <div
      v-if="route.path != '/casino/home' && route.path != '/casino/providers'"
      class="provider-select-wrapper"
    >
      <ProviderSelector class="provider-select" />
      <SortSelector />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/thin-scrollbar.scss";

.categories {
  &::-webkit-scrollbar {
    height: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.filters {
  margin-top: 10px;
  display: flex;

  .search {
    background-color: var(--be-big-card-bg);
    display: flex;
    gap: 10px;
    border: 1px solid var(--be-divider);
    border-radius: 8px;
    height: 47px;
    width: 70%;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--be-primary-btn);
    }

    &:focus-within {
      border-color: var(--be-primary-btn);
    }
  }

  .search-full {
    flex-basis: 100%;
  }

  .search-input {
    width: 100%;
    outline: none;
    font-weight: 400;
    font-family: Rubik;
  }
  input::placeholder {
    color: var(--be-disable-state);
  }
  .prepend-inner {
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 8px;
    padding: 0px 0 0 10px;
    margin-right: 5px;
    color: var(--be-disable-state);
  }

  .provider-select-wrapper {
    display: flex;
  }
}

.panel-wrapper {
  background-color: var(--be-big-card-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  .mobile &,
  .tablet & {
    display: block;
  }

  .categories {
    display: flex;
    padding: 8px 10px 8px;
    overflow-y: auto;
    width: 100%;
    border-radius: 10px;

    .category {
      width: 116px;
      height: 63px;
      gap: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // white-space: nowrap;
      // width: 137px;
      min-width: auto;
      // opacity: 0.5;
      color: var(--be-disable-state);
      // color: #ccc;
      // margin: 0 3px;
      font-weight: 600;
      // font-size: 11px;
      // text-transform: uppercase;
      letter-spacing: 1px;
      padding: 0px;
      font-family: Rubik;
      .mobile &,
      .tablet & {
        font-size: 10px;
        // flex-direction: column-reverse;
      }

      .category-name {
        // margin: 2px 0 0;
        text-transform: none;
        // height: 2.5em;
        display: flex;
        // width: 119px;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        font-size: 14px;
        font-family: Rubik;
        font-weight: 400;

        // .mobile &,
        // .tablet & {
        //   margin: 0 0 5px;
        // }
      }

      img {
        max-width: 24px;
        height: 24px;
      }
    }

    @media screen and (max-width: 550px) {
      .category {
        // min-width: 135px;
        width: auto;
        padding: 0 10px;
        gap: 7px;
        min-width: max-content;
      }
    }

    .activing {
      opacity: 1;
      background: var(--be-small-card-bg);
      color: var(--be-primary-btn);
      border-radius: 6px;

      .category-name {
        filter: drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5));
      }
    }

    // .router-link-exact-active .category,
    // .router-link-active .category {
    //   opacity: 1 !important;
    // }
  }

  @media screen and (max-width: 550px) {
    .categories {
      padding: 5px 8px 4px;
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 550px) {
  .filters {
    display: flex;
    flex-direction: column;

    .search {
      width: 100%;
    }

    .search-full {
      flex-basis: auto;
    }

    .provider-select-wrapper {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
