// src/utils/timerUtils.js
import { ref, computed } from "vue";

export function useStartTimer(targetTime) {
  const second = ref(0);
  const minutes = ref(0);
  const hour = ref(0);
  const now = new Date().getTime();
  let timeDifference = now - targetTime;

  const interval = setInterval(() => {
    if (timeDifference <= 0) {
      const totalSeconds = -Math.floor(timeDifference / 1000);
      second.value = totalSeconds % 60;
      minutes.value = Math.floor((totalSeconds % 3600) / 60);
      hour.value = Math.floor(totalSeconds / 3600);
      timeDifference += 1000;
    } else {
      hour.value = 0;
      minutes.value = 0;
      second.value = 0;
      clearInterval(interval);
    }
  }, 1000);

  return computed(
    () =>
      `${hour.value.toString().padStart(2, "0")}hr : ${minutes.value
        .toString()
        .padStart(2, "0")}m : ${second.value.toString().padStart(2, "0")}s`
  );
}
