<script setup>
const props = defineProps({
  match: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  league: {
    type: String,
    required: true,
  },
  selectedGames: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["select-odd"]);

function handleOddClick(prediction) {
  emit("select-odd", {
    id: props.match.id,
    prediction,
  });
}

function isSelected(prediction) {
  return props.selectedGames.some(
    (game) => game.gameId === props.match.id && game.prediction === prediction
  );
}

function formatTime(dateString) {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${day}/${month} ${hours}:${minutes}`;
}
</script>

<template>
  <div class="match-item">
    <div class="match-header">
      <span class="match-time">{{ formatTime(match.startDate) }}</span>
      <span class="league-name">{{ league }}</span>
    </div>
    <div class="match-content">
      <div class="teams-scores">
        <div class="team-row">
          <span class="team-name">{{ match.homeTeam }}</span>
          <!-- <span v-if="index === 0" class="score">1</span> -->
        </div>
        <div class="team-row">
          <span class="team-name">{{ match.awayTeam }}</span>
          <!-- <span v-if="index === 0" class="score">2</span> -->
        </div>
      </div>
      <div class="odds">
        <button
          class="odds-button normal"
          :class="isSelected('home') ? 'primary' : 'normal'"
          @click="handleOddClick('home')"
        >
          Home
        </button>
        <button
          class="odds-button normal"
          :class="isSelected('draw') ? 'primary' : 'normal'"
          @click="handleOddClick('draw')"
        >
          Draw
        </button>
        <button
          class="odds-button normal"
          :class="isSelected('away') ? 'primary' : 'normal'"
          @click="handleOddClick('away')"
        >
          Away
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.match-item {
  border-bottom: 1px solid var(--be-input-bg);
  padding: 0px 20px;
  padding-bottom: 15px;
}

.odds-button.normal {
  border: 1px solid var(--be-input-bg);
}

.match-item:last-child {
  border-bottom: none;
}

.match-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.match-time {
  font-size: 14px;
  color: white;
  font-family: Poppins-reg;
}

.league-name {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: Poppins-reg;
}

.match-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teams-scores {
  flex: 1;
  font-family: Poppins;
  font-size: 16px;
}

.team-row {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 0px;
}

.team-name {
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.score {
  color: white;
  font-weight: 500;
}

.odds {
  display: grid;
  grid-template-columns: repeat(3, 80px);
  gap: 12px;
}

.odds-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 44px;
  transition: opacity 0.2s;
}

.odds-button:hover {
  opacity: 0.9;
}

.odds-button.primary {
  background-color: #e6943f;
  color: white;
}

.odds-button.secondary {
  background-color: #1f2037;
  color: white;
}

.odds-value {
  font-size: 16px;
  font-family: Poppins;
}

@media (max-width: 550px) {
  .odds {
    gap: 8px;
    grid-template-columns: repeat(3, 49px);
  }
  .match-item {
    padding: 0 0px 10px 0px;
  }
  .odds-value {
    font-size: 11px;
  }

  .odds-button {
    height: 36px;
    font-size: 11px;
  }

  .league-name {
    font-size: 12px;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }

  .match-header {
    margin-bottom: 10px;
  }

  .match-time {
    font-size: 12px;
  }

  .teams-scores {
    font-size: 12px;
  }

  .team-name {
    font-size: 12px;
  }
}

.odd-button.selected {
  background-color: var(--be-primary-btn);
  color: white;
}
</style>
