<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";

const props = defineProps({
  show: Boolean,
  freeAwardHeading: {
    type: String,
  },
  imgSrc: {
    type: String,
    default: "/assets/images/freebet.svg",
  },
});

const emit = defineEmits(["close"]);

const { freeBets } = useFreeBets();

// Create computed property for the award text
const awardText = computed(
  () =>
    `You have been awarded a free bet for ${freeBets.value?.[0]?.amount} (${freeBets.value?.[0]?.currency?.ticker}) use on Sports`
);

const { t } = useI18n();

// Define the target expiration date and time
const targetDate = new Date(freeBets.value?.[0]?.expiredAt);

// Define reactive variables for the timer display
const days = ref("00");
const hours = ref("00");
const minutes = ref("00");
const seconds = ref("00");

// Function to calculate the remaining time
function updateTimer() {
  const now = new Date();
  const timeDifference = targetDate - now;

  if (timeDifference <= 0) {
    // If the countdown is over, set all to 00
    days.value = "00";
    hours.value = "00";
    minutes.value = "00";
    seconds.value = "00";
    clearInterval(intervalId); // Stop the timer
  } else {
    // Calculate days, hours, minutes, and seconds
    const totalSeconds = Math.floor(timeDifference / 1000);
    const remainingDays = Math.floor(totalSeconds / (60 * 60 * 24));
    const remainingHours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const remainingMinutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const remainingSeconds = Math.floor(totalSeconds % 60);

    // Update the reactive variables
    days.value = String(remainingDays).padStart(2, "0");
    hours.value = String(remainingHours).padStart(2, "0");
    minutes.value = String(remainingMinutes).padStart(2, "0");
    seconds.value = String(remainingSeconds).padStart(2, "0");
  }
}

// Set up an interval to update the timer every second
let intervalId;
onMounted(() => {
  updateTimer(); // Initial call to display the timer immediately
  intervalId = setInterval(updateTimer, 1000); // Update every second
});

// Clean up the interval when the component is unmounted
onBeforeUnmount(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask-bonus">
      <div class="modal-container">
        <div class="modal-detail">
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="" />
          </div>

          <div class="modal-body">
            <img :src="imgSrc" alt="" />
            <div class="free-bet">
              <div class="free-heading">Congratulations!</div>
              <div class="free-award">
                {{ freeAwardHeading ? freeAwardHeading : awardText }}
              </div>
              <div v-if="!freeAwardHeading" class="free-award">Free bet valid until:</div>
              <div class="times">
                <div>
                  <div class="time">
                    {{ days }}
                  </div>
                  <div class="time-label">days</div>
                </div>
                <div class="time">:</div>
                <div>
                  <div class="time">
                    {{ hours }}
                  </div>
                  <div class="time-label">hours</div>
                </div>
                <div class="time">:</div>
                <div>
                  <div class="time">
                    {{ minutes }}
                  </div>
                  <div class="time-label">minutes</div>
                </div>
                <div class="time">:</div>
                <div>
                  <div class="time">
                    {{ seconds }}
                  </div>
                  <div class="time-label">seconds</div>
                </div>
              </div>
              <button class="btn" @click="() => emit('close', false)">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.modal-mask-bonus {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.free-bet {
  border-radius: 20px;
  background: var(--be-big-card-bg);
  padding: 30px;
  margin-top: 30px;

  .btn {
    background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
    color: var(--be-primary-btn-label);
    font-size: 16px;
    font-family: Poppins;
    border-radius: 8px;
    width: 100%;
    height: 51px;
    margin-top: 30px;
    outline: none;
  }
  .free-heading {
    font-size: 24px;
    font-family: Poppins-bold;
    text-align: center;
    margin-bottom: 30px;
  }
  .free-award {
    font-size: 16px;
    font-family: Poppins-reg;
    margin-bottom: 30px;
    text-align: center;
  }

  .times {
    display: flex;
    justify-content: space-between;
    .time {
      font-size: 48px;
      font-family: Poppins;
    }
    .time-label {
      font-family: Poppins-reg;
      font-size: 14px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .modal-mask-bonus {
    top: 2;
  }
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 600px;
  margin: auto;
  // padding: 0px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

@media screen and (max-width: 500px) {
  .modal-container {
    padding: 0 16px 16px 16px;
  }
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  // margin: auto;
  margin: 100px 0 auto 0;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .modal-detail {
    padding: 0px;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  // background: var(--be-big-card-bg);
  padding: 10px 10px 0px 10px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
