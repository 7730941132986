import { ref } from "vue";

const geoblocked = ref(false);
const systemSettings = ref(false);
const registrationFlow = ref(false);
const isDrawerOpen = ref(true);
const appDivisions = ref([]);
const appDivision = ref();

export function useConfig() {
  function setSystemSettings(_systemSettings) {
    systemSettings.value = _systemSettings;

    appDivisions.value = ((_systemSettings.divisions as any[]) ?? [])
      .filter(division => division.active)
      .map(division => division.name);

    // appDivisions.value.push("survivor");
  }

  function setRegistrationFlow(_registrationFlow) {
    registrationFlow.value = _registrationFlow;
  }

  return {
    appDivision,
    appDivisions,
    isDrawerOpen,
    geoblocked,
    systemSettings,
    setSystemSettings,
    registrationFlow,
    setRegistrationFlow,
  };
}
