<script setup>
import { computed, onMounted, watch } from "vue";

import { useBetStore } from "../../../store";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import CasinoPanel from "@/casino/components/CasinoPanel.vue";
import ProviderList from "@/casino/components/ProviderList.vue";
import CategoryGameList from "@/casino/components/CategoryGameList.vue";
import GameList from "@/casino/components/GameList.vue";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { router, useRoute } from "@/app/router";
import Banner from "@/app/components/Banner.vue";
import { useLogoBanner } from "@/app/composables/useLogoBanner";

const store = useBetStore();
const route = useRoute();

const selectedProviders = computed(() => store.selectedProviders);

const { banners } = useLogoBanner();

const providerId = computed(() => route.params.providerId);
const category = computed(() => route.params.category);

const { state, providers, categories, loading, initCasino } = useCasinoService();

watch(
  providerId,
  async (newValue) => {
    if (newValue) {
      await initCasino({ [newValue]: true });
    }
  },
  { immediate: true }
);

onMounted(async () => {
  Object.keys(selectedProviders.value)
    .filter((key) => selectedProviders.value[key])
    .reduce((res, key) => {
      res[key] = selectedProviders.value[key];
      return res;
    }, {});

  if (Object.keys(selectedProviders.value).length != 0 && !providerId.value) {
    await initCasino(selectedProviders.value);
  } else if (!providerId.value) {
    await initCasino();
  }
});
</script>

<template>
  <article class="casino-view">
    <div class="banner">
      <Banner :imgs="banners?.casino" />
    </div>
    <ProgressLoader :loading="loading" />
    <CasinoPanel />
    <ProviderList
      v-if="
        (category == 'home' || category == 'providers') &&
        !providerId &&
        state.category.id == 0 &&
        providers.length > 1 &&
        (Object.keys(
          Object.keys(selectedProviders)
            .filter((key) => selectedProviders[key])
            .reduce((res, key) => {
              res[key] = selectedProviders[key];
              return res;
            }, {})
        ).length == 0 ||
          router.currentRoute.value.href == '/casino/home')
      "
      v-memo="[providers.length]"
    />

    <template v-if="state.category.id === 0 && router.currentRoute.value.href == '/casino/home'">
      <CategoryGameList v-for="(category, index) in categories" :key="index" :category="category" />
    </template>

    <GameList
      v-else-if="
        providerId ||
        ((state.category.id > 0 ||
          (state.category.id == 0 &&
            Object.keys(
              Object.keys(selectedProviders)
                .filter((key) => selectedProviders[key])
                .reduce((res, key) => {
                  res[key] = selectedProviders[key];
                  return res;
                }, {})
            ).length > 0)) &&
          !loading)
      "
    />
  </article>
</template>

<style scoped>
.banner-img {
  width: 100%;
}
.casino-view {
  width: 100%;
  min-height: 70vh;
  /* padding: 0 14px; */
}

@media screen and (max-width: 500px) {
  .casino-view {
    padding: 0 14px;
  }
}
.note {
  margin: 20px;
}
</style>
