<script setup>
import { computed, watch } from "vue";
import { useBetStore } from "../../../store";
import { useStartTimer } from "../hooks/timerFunction";
import MatchItem from "./MatchItem.vue";
import TournamentContent from "./TournamentContent.vue";
import { useI18n } from "@/app/composables/useI18n";

const props = defineProps({
  showMorePicks: {
    type: Boolean,
    required: true,
  },

  predictions: {
    type: Object,
    required: true,
  },

  selectedGames: {
    type: Array,
    required: true,
  },
  item: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["close", "freeModal", "submit", "update:selectedGames"]);

const store = useBetStore();
let dateTime = null;
let calculateCountdown = null;
watch(
  () => props?.item?.entryCloseTime,
  (newVal) => {
    dateTime = new Date(newVal);
    calculateCountdown = useStartTimer(dateTime);
  },
  {
    immediate: true,
  }
);

const isMobile = computed(() => {
  return store.getScreenWidth < 512;
});

const { t } = useI18n();

function handleSelectPrediction(odd) {
  const existingIndex = props.selectedGames.findIndex(
    (item) => item.gameId === odd.id && item.prediction === odd.prediction
  );

  if (existingIndex !== -1) {
    // Remove the prediction
    emit("update:selectedGames", [
      ...props.selectedGames.slice(0, existingIndex),
      ...props.selectedGames.slice(existingIndex + 1),
    ]);
  } else {
    // Replace if different prediction for same game, or add new
    const sameMatchIndex = props.selectedGames.findIndex((item) => item.gameId === odd.id);
    if (sameMatchIndex !== -1) {
      const updatedGames = [...props.selectedGames];
      updatedGames[sameMatchIndex] = {
        gameId: odd.id,
        prediction: odd.prediction,
      };
      emit("update:selectedGames", updatedGames);
    } else {
      emit("update:selectedGames", [
        ...props.selectedGames,
        {
          gameId: odd.id,
          prediction: odd.prediction,
        },
      ]);
    }
  }
}
</script>

<template>
  <div v-if="showMorePicks" class="games-card-container">
    <div v-if="isMobile" class="back-container" @click="$emit('closeModal')">
      <img src="/assets/images/back.svg" alt="" />
      <div>Back to Survivor Lobby</div>
    </div>
    <TournamentContent v-if="isMobile" :item="item" :container="false" />
    <div v-if="isMobile" class="choose-pick">
      Choose your pick for each game and click Enter Last entry in:<span class="entry">
        {{ calculateCountdown }}</span
      >
    </div>

    <div>
      <div class="card games-card">
        <div class="close-btn mbl-none" @click="$emit('closeModal')">
          <div>{{ t("general.close") }}</div>
          <img src="/assets/images/close-modal-icon.svg" alt="" />
        </div>
        <div class="games-header">
          <div class="games-count">{{ item.games.length }} Games</div>
          <div class="odds-header">
            <span>1</span>
            <span>X</span>
            <span>2</span>
          </div>
        </div>

        <div class="matches-list">
          <MatchItem
            v-for="(match, index) in item.games"
            :key="index"
            :match="match"
            :index="index"
            :league="item.league"
            :selected-games="selectedGames"
            @select-odd="handleSelectPrediction"
          />
        </div>
      </div>
      <div v-if="!item.locked">
        <button
          v-if="predictions?.predictions?.length === 0"
          class="enter-button"
          :class="{ disabled: selectedGames.length !== item.games.length }"
          :disabled="selectedGames.length !== item.games.length"
          @click="$emit('submit', 'hello', 'jejje')"
        >
          Enter (Free)
          <span class="button-count">{{ selectedGames.length }}</span>
        </button>
        <div v-else class="edit" @click="$emit('submit')">
          <div>
            <div class="edit-text">You are entered</div>
            <div>
              <span class="edit-text">Picks can be edited for</span>
              <span class="edit-time">{{ calculateCountdown }}</span>
            </div>
          </div>
          <button class="edit-btn" @click="$emit('submit')">Edit picks</button>
        </div>
      </div>
      <div v-else class="status">
        {{ predictions?.status }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.games-card-container {
  padding: 30px 30px 20px 30px;
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  height: fit-content;
  background-color: var(--be-big-card-bg);
  border: 1px solid var(--be-input-bg);
}

.status {
  color: #d80027;
  text-align: center;
  font-family: Poppins-med;
  margin-top: 20px;
}

.edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.edit-btn {
  background-color: var(--be-primary-btn);
  width: 125px;
  height: 51px;
  border-radius: 8px;
}

.back-container {
  display: flex;
  gap: 4px;
  margin: 16px 0;
}

.choose-pick {
  color: rgba(255, 255, 255, 0.5);
  font-family: Poppins-reg;
  text-align: center;
  margin-bottom: 15px;
}

.entry {
  color: white;
  font-family: Poppins;
}

.close-btn {
  color: white;
  position: absolute;
  top: -39px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.card {
  background-color: var(--be-big-card-bg);
  border-radius: 20px;
  color: white;
  border: 1px solid var(--be-input-bg);
}

.games-card {
  flex: 1;
  max-width: 600px;
  position: relative;
  margin-top: 20px;
}

.games-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: var(--be-small-card-bg);
  border-radius: 12px 12px 0px 0px;
  margin-bottom: 24px;
}

.games-count {
  font-size: 16px;
  font-family: Poppins-reg;
  color: white;
}

.odds-header {
  display: grid;
  grid-template-columns: repeat(3, 72px);
  gap: 12px;
  text-align: center;
  color: white;
  font-family: Poppins;
}

.matches-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.enter-button {
  width: 100%;
  background-color: var(--be-primary-btn);
  color: white;
  border: none;
  padding: 16px;
  border-radius: 12px;
  margin-top: 24px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: opacity 0.2s;
}

.enter-button.disabled {
  background-color: #4b5563;
  cursor: not-allowed;
  opacity: 0.7;
}

.enter-button.disabled:hover {
  opacity: 0.7;
}

.enter-button:hover {
  opacity: 0.9;
}

.button-count {
  background-color: white;
  color: #4f46e5;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 550px) {
  .mbl-none {
    display: none;
  }
  .edit-text {
    color: rgba(255, 255, 255, 0.5);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .enter-button {
    /* position: absolute; */
    bottom: 0px;
    width: 100%;
    padding: 13px 16px;
    border-radius: 8px;
  }

  .games-card-container {
    border: none;
    height: fit-content;
    overflow: hidden;
    display: flex;
    border-radius: 0px;
    flex-direction: column;
    padding: 0 16px;
    padding-bottom: 10px;
  }

  .games-card {
    max-width: 100%;
    margin-top: 0px;
    border-radius: 8px;
  }
  .choose-pick {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .games-header {
    border-radius: 8px 8px 0px 0px;
    padding: 10px;
    margin-bottom: 15px;
  }

  .matches-list {
    padding: 0px 16px;
  }

  .odds-header {
    grid-template-columns: repeat(3, 55px);
    gap: 0px;
  }
  .edit-btn {
    display: flex;
    height: 50px;
    padding: 20px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .edit-time {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
}
</style>
