<script setup>
// import { ref, getCurrentInstance } from "vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { router, useRoute } from "@/app/router";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import "vue-select/dist/vue-select.css";

import { useI18n } from "@/app/composables/useI18n";
import { useBetStore } from "../../../store";

const store = useBetStore();

const selectedProviders = computed(() => store.selectedProviders);

const route = useRoute();
const menu = ref(false);
const { t } = useI18n();
const { state, providers, updateGames, initCasino } = useCasinoService();
// const checkIcon = ref("mdi-menu-down");
// const customColor = ref("");
const clearable = ref(true);

const sortedProviders = computed(() => {
  const sp = state.providers;

  return sp.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
});

const suffix = computed(() => {
  return state.selectedProviders.length > 1 ? ` + ${state.selectedProviders.length - 1}` : "";
});

const selectedOptions = computed({
  get() {
    return state.selectedProviders;
  },
  set(val) {
    const noChanges =
      state.selectedProviders
        .filter((x) => !val.includes(x))
        .concat(val.filter((x) => !state.selectedProviders.includes(x))).length === 0;

    if (noChanges) {
      return;
    }

    let update = false;

    if (val.length > 0) {
      update = true;
    }

    // inner strange behavour
    if (state.category.id !== -1) {
      update = true;
    }

    if (providers.value.length !== 1 && val.length !== 0) {
      update = true;
    }

    if (update) {
      state.selectedProviders = val;
      if (val.length === 0 && state.providers.length === providers.length) {
        state.providers = providers.value;
        updateGames();
      }
    }
  },
});

function deselect() {
  if (route.name === "casino.provider") {
    router.push({
      name: "casino.category",
      params: { category: "providers" },
    });
  }
}

async function onProvidersChange(e, providers) {
  store.handleSelectedProvider(providers.slug, e.target.checked);
  const filteredObj = Object.keys(selectedProviders.value)
    .filter((key) => selectedProviders.value[key])
    .reduce((res, key) => {
      res[key] = selectedProviders.value[key];
      return res;
    }, {});

  await initCasino(filteredObj);
}

const isMenuOpen = ref(false);
const menuRef = ref(null);

function handleClickOutside(event) {
  if (menuRef.value && !menuRef.value.contains(event.target)) {
    isMenuOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<template>
  <div ref="menuRef" class="select-provider" :class="{ 'menu-open': isMenuOpen }">
    <div v-if="!route.params.providerId" class="select-box">
      <!-- <div class="left-icon">
        <img class="left-img" src="@/assets/images/icons/providers.svg" alt="" />
      </div> -->
      <div class="right-box" @click="toggleMenu">
        <div>
          Providers:
          <span class="selected-providers">
            {{
              Object.keys(selectedProviders).filter((key) => selectedProviders[key]).length > 0
                ? Object.keys(selectedProviders).filter((key) => selectedProviders[key]).length
                : "All"
            }}
          </span>
        </div>
        <img
          class="arrow"
          :src="`/assets/images/${isMenuOpen ? 'up-arrow' : 'down-white'}.svg`"
          alt=""
        />
      </div>
    </div>
    <div v-if="isMenuOpen" class="select-menu">
      <div v-for="item in sortedProviders" :key="item.id" class="item">
        <label class="container">
          {{ item.name }}

          <input
            type="checkbox"
            :checked="selectedProviders[item.slug]"
            @change="(e) => onProvidersChange(e, item)"
          />
          <span class="checkmark" />
        </label>
      </div>
    </div>
  </div>
  <!-- <vueSelect
    v-model="selectedOptions"
    value11="state.selectedProviders"
    :options="state.providers"
    :deselect-from-dropdown="true"
    :reset-on-options-change="true"
    :placeholder="t('casino.providers')"
    label="name"
    :reduce="(provider) => provider.id"
    class="provider-selector"
    :clearable="clearable"
    :searchable="true"
    multiple
    clear-search-on-blur1="false"
    :dropdown-should-open1="() => true"
    @input="onProvidersChange"
    @option:selected="onProvidersChange"
    @option:deselected="deselect"
  >
    <template #selected-option="{ id, name }">
      <div :class="{ hidden: state.selectedProviders[0] !== id }" class="option" :data-id="id">
        {{ ellipsis(name, 8) + suffix }}
      </div>
    </template>
    <template #option="{ id, name }">
      <div class="option" :class="{ selected: state.selectedProviders.includes(id) }">
        {{ name }}
      </div>
    </template>
  </vueSelect> -->
</template>

<style lang="scss" scoped>
.selected-providers {
  color: var(--be-primary-btn);
}
.provider-selector {
  // max-width: 230px;
  // background-color: rgb(48, 49, 79);
  background-color: var(--be-small-card-bg);
  border-radius: 4px;
  // height: 52px;
  margin-left: 10px;
  min-width: 170px;
  padding-top: 4px;
  .tablet & {
    max-width: unset;
  }
}
// .chip-wrapper {
//   display: flex;
//   align-items: center;
//   max-width: 140px;
//   .chip {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     // width: 100px;
//   }
// }
.option {
  display: flex;
  align-items: baseline;
  padding: 3px 7px;
  white-space: nowrap;
  // &.selected {
  //   background-color: #ccc;
  // }
}
</style>

<style lang="scss">
.provider-selector {
  .vs__dropdown-option--deselect {
    background: unset;
    color: unset;
  }
  .vs__dropdown-option--selected {
    background-color: #ccc;
  }

  .not-empty .v-select__selections input {
    display: none;
  }
  .v-input__icon--append .v-icon {
    transform: none !important;
  }
  .vs__search {
    cursor: pointer;
  }
  .vs__selected {
    color: #333;
    background: rgb(255, 232, 26);
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 12px inset;
    /* max-width: 100px; */
  }
  .vs__deselect {
    color: #555;
    min-width: 10px;
  }
  .vs__actions {
    min-width: 25px;
    height: 35px;
    cursor: pointer;
  }
  .vs__dropdown-menu {
    left: unset;
    right: 0;
    overflow-x: hidden;
    width: fit-content;
    top: calc(100% + 7px);
    border-radius: 3px;
  }
  .vs__selected:has(> .hidden) {
    display: none;
  }
  .vs__open-indicator {
    fill: white;
  }
}

.select-provider {
  position: relative;
  margin-left: 16px;
}

.arrow {
  width: 16px;
}
.right-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 12px;
  font-family: Rubik;
  font-weight: 400;
}

.select-box {
  border-radius: 8px;
  border: 1px solid var(--be-divider);
  background: var(--be-big-card-bg);
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 47px;
  min-width: 250px;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--be-primary-btn);
  }

  .select-provider.menu-open & {
    border-color: var(--be-primary-btn);
  }

  .left-icon {
    cursor: pointer;
    background: var(--be-secondary-btn);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    margin-right: 15px;
  }
}

.select-menu {
  border-radius: 8px;
  border: 1px solid var(--be-small-card-bg);
  background: var(--be-big-card-bg);
  box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;
  padding: 16px;
  width: 100%;
  height: 350px;
  overflow-y: auto;
  margin-top: 9px;
  z-index: 7;

  .col {
    margin-bottom: 12px;
  }

  .item {
    display: flex;
    flex-basis: 33.33%;
    gap: 0px 6px;
    margin-bottom: 14px;

    .container {
      display: block;
      position: relative;
      padding-left: 23px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: Rubik;
      padding-top: 2px;

      color: var(--White, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 4px;
      background-color: var(--be-small-card-bg);
      border: 1px solid #333360;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: var(--be-small-card-bg);
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: var(--be-primary-btn);
      border: 1px solid var(--be-primary-btn);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 5.5px;
      top: 2px;
      width: 5px;
      height: 9px;
      border: solid var(--be-primary-btn-label);
      border-width: 0 1.5px 1.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

@media screen and (max-width: 500px) {
  .select-provider {
    position: relative;
    margin-left: 0px;
    width: 50%;
  }

  .select-menu {
    width: 100%;
    transform: translate(-50%, 0%);
    left: 50%;
    .item {
      flex: 50%;
    }
  }
  .select-box {
    min-width: 50%;
    height: 47px;
    border-radius: 8px;
    .left-icon {
      cursor: pointer;
      margin-right: 0px;
      background: transparent;
      padding: 0px 8px 0 10px;

      .left-img {
        width: 16px;
      }
    }

    .right-box {
      width: 100%;
    }
  }
}
</style>
